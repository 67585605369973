import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import {
    ContentHeader,
    ContentSection,
    FeaturesCard,
    Testimonial,
    TestimonialCard,
    ConsultationContent,
} from '../components/Common'
import { useCybersecurityCompliance } from '../GraphQL/Queries'
import { MainHero } from '../components/Hero'

const CybersecurityCompliance = () => {
    const data = useCybersecurityCompliance()
    const hero = {
        image: data.acf_hero.heroImage.sourceUrlSharp.childImageSharp.fluid,
        altText: data.acf_hero.heroImage.altText,
        title: data.acf_hero.bbTitle,
        description: data.acf_hero.bbDescription,
    }

    const contentHeader = {
        title: data.acf_cybersecurity_compliance_content.mainTitle,
        description: data.acf_cybersecurity_compliance_content.mainDescription,
    }

    const contentSection = {
        title: data.acf_cybersecurity_compliance_content.benefits[0].title,
        description:
            data.acf_cybersecurity_compliance_content.benefits[0].description,
        image:
            data.acf_cybersecurity_compliance_content.benefits[0].sectionImage
                .sourceUrlSharp.childImageSharp.fluid,
    }

    const featuresCard = data.acf_cybersecurity_compliance_content.features

    const testimonial = {
        title: data.acf_testimonial_title_rating.testimonialTitle,
    }

    const testimonialCard = data.acf_testimonials.cards

    const consultationContent = {
        title: data.acf_schedule_free_consultation_content.mainTitle,
        subTitle: data.acf_schedule_free_consultation_content.subTitle,
        description: data.acf_schedule_free_consultation_content.description,
       // image:
        //    data.acf_schedule_free_consultation_content.sectionImage
         //       .sourceUrlSharp.childImageSharp.fluid,
    }

    return (
        <Layout>
            <div className="cybersecurity-compliance-parent">
                <SEO title="Cybersecurity compliance" />
                <MainHero
                    alt={hero.altText}
                    image={hero.image}
                    title={hero.title}
                    description={hero.description}
                />

                <ContentHeader
                    title={contentHeader.title}
                    description={contentHeader.description}
                    small={false}
                />
                <ContentSection
                    title={contentSection.title}
                    description={contentSection.description}
                    image={contentSection.image}
                />
                <div className="features-card-repeater-section">
                    <div className="container">
                        <div className="features-card-repeater-row">
                            {featuresCard.length > 0 &&
                                featuresCard.map(feature => {
                                    return (
                                        <FeaturesCard
                                            title={feature.title}
                                            description={feature.description}
                                            image={feature.icon.sourceUrl}
                                        />
                                    )
                                })}
                        </div>
                    </div>
                </div>
                <div className="testimonial-section">
                    <div className="container">
                        <Testimonial
                            title={testimonial.title}
                            images={testimonial.images || []}
                        />
                        <div className="testimonial-row">
                            {testimonialCard.length > 0 &&
                                testimonialCard.map(testimonial => {
                                    return (
                                        <TestimonialCard
                                            title={testimonial.name}
                                            description={
                                                testimonial.description
                                            }
                                            position={testimonial.position}
                                            //logo={
                                            //    testimonial.profilePhoto
                                            //        .sourceUrl
                                           // }
                                        />
                                    )
                                })}
                        </div>
                    </div>
                </div>
                <ConsultationContent
                    title={consultationContent.title}
                    subTitle={consultationContent.subTitle}
                    description={consultationContent.description}
                    image={consultationContent.image}
                />
            </div>
        </Layout>
    )
}

export default CybersecurityCompliance
